.header {
    position: relative;
    height: 60px;
    width: auto;

    @include media-queries(1280px) {
        height: 150px;
    }

    @include media-queries(1700px) {
        height: 150px;
    }

    @include media-queries(2400px) {
        height: 150px;
    }

    &__logo {
        position: absolute;
        display: inline-block;
        left: 50%;
        color: $blue;
        transform: translateX(-50%);
        top: 20px;
        width: 300px;
        text-align: center;
        h1 {
            font-weight: normal;
            font-size: 1.8rem;
            color: $blue;
            margin: auto;
            padding: 0;
        }

        @include media-queries(1280px) {
            top: 60px;
            h1 {
                font-size: 2.7rem;
            }
        }

        @include media-queries(1700px) {
            top: 60px;
        }

        @include media-queries(2400px) {
            top: 60px;
        }
    }
}
