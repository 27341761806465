.footer-element {
  width: 150px;
  display: block;
  margin: auto;
  margin-bottom: 50px;

  &:hover {
    cursor: pointer;
  }

  @include media-queries(1280px) {
    width: 200px;
    margin-bottom: 0;
  }

  @include media-queries(1700px) {
    width: 275px;
    margin-bottom: 0;
  }

  @include media-queries(2400px) {
    width: 325px;
    margin-bottom: 0;
  }

}

.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;

  @include box-sizing(border-box);

  @include media-queries(1280px) {
    flex-direction: row;
  }

  &__contact {
    margin-left: 10%;

    @include box-sizing(border-box);

    @include media-queries(1280px) {
      width: 75%;
      margin-left: 25%;
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;

    margin: 35px 0 30px 0;

    @include media-queries(1280px) {
      margin: 0 0 10px 0;
      flex-direction: row;
    }

    @include media-queries(1700px) {
      margin: 0 0 50px 0;

    }

    &__item {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: $blue;
      font-size: 1.6rem;
      line-height: 1.5;
      // margin: auto;
      margin-bottom: 20px;
      // text-align: left;

      @include media-queries(1280px) {
        font-size: 1.5rem;
        width: 33%;
        // margin: auto;
        margin-bottom: 50px;
      }

      @include media-queries(1700px) {
        font-size: 1.8rem;
      }

      @include media-queries(2400px) {
        font-size: 2.2rem;
        margin: auto;
        margin-bottom: 75px;
      }

      h4 {
        display: block;
        font-weight: normal;
        letter-spacing: .25rem;
        margin-bottom: 1rem;
      }

      a {
        position: relative;
        color: $blue;
        display: inline-block;
        // align-self: center;

        // &:last-of-type {
        //   &:hover {
        //     &:after {
        //       width: 100%;
        //     }
        //   }
        // }

        // &:last-of-type {
        //   &:after {
        //     @include media-queries(1280px) {
        //       position: absolute;
        //       content: "";
        //       height: 1px;
        //       left: 0;
        //       bottom: -5px;
        //       background-color: $blue;
        //       width: 0;
        //
        //       @include transition(width 0.3s ease-in-out);
        //     }
        //
        //     @include media-queries(2400px) {
        //       height: 2px;
        //     }
        //   }
        // }
      }
    }
  }

  &__team {
    width: 44%;
    align-self: center;

    p {
      color: $blue;
      font-size: 4.5rem;
      text-align: center;
      margin: 0 0 148px 0;
      display: block;

      @include media-queries(1280px) {
        display: block;
        font-size: 3rem;
        margin: 0 0 66px 0;
      }

      @include media-queries(1700px) {
        font-size: 3.8rem;
        margin: 0 0 92px 0;
      }

      @include media-queries(2400px) {
        font-size: 4.5rem;
        margin: 0 0 148px 0;
      }
    }
  }

  &__brands {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    &__item {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      width: 50%;
      align-items: center;

      @include box-sizing(border-box);

      &__img--1 {
        background: url("../img/icon-jll.svg") no-repeat left center;
        background-size: contain;
        width: 68px;
        height: 30px;
        margin: 0 0 37px 0;

        @include media-queries(1280px) {
          width: 118px;
          height: 52px;
          margin: 0 0 75px 0;
        }

        @include media-queries(1700px) {
          width: 157px;
          height: 70px;
          margin: 0 0 100px 0;
        }

        @include media-queries(2400px) {
          width: 190px;
          height: 84px;
          margin: 0 0 100px 0;
        }
      }

      &__img--2 {
        background: url("../img/icon-savanna.svg") no-repeat left center;
        background-size: contain;
        width: 41px;
        height: 42px;
        margin: 0 0 37px 0;

        @include media-queries(1280px) {
          width: 75px;
          height: 76px;
          margin: 0 0 75px 0;
        }

        @include media-queries(1700px) {
          width: 100px;
          height: 101px;
          margin: 0 0 100px 0;
        }

        @include media-queries(2400px) {
          width: 116px;
          height: 118px;
          margin: 0 0 100px 0;
        }
      }
    }
  }

  &__credit {
    width: 100%;
    @include box-sizing(border-box);
    // @include media-queries(1280px) {
    //   width: 100%;
    // }
  }

  &__credits {
    p {
      color: $blue;
      font-size: 1rem;
      text-align: center;
      line-height: 1.25rem;
      margin: 0 0 37px 0;
      display: block;

      @include media-queries(1280px) {
        display: block;
        font-size: 1.5rem;
        line-height: 1.8rem;
        margin: 0 0 75px 0;
      }

      @include media-queries(1700px) {
        font-size: 1.8rem;
        line-height: 2.1rem;
        margin: 0 0 130px 0;
      }

      @include media-queries(2400px) {
        font-size: 2.2rem;
        line-height: 2.7rem;
        margin: 0 0 130px 0;
      }
    }

    a {
      position: relative;
      color: $blue;
      display: inline-block;
      align-self: center;

      &:last-of-type {
        &:hover {
          &:after {
            width: 100%;
          }
        }
      }

      &:last-of-type {
        &:after {
          @include media-queries(1280px) {
            position: absolute;
            content: "";
            height: 1px;
            left: 0;
            bottom: -5px;
            background-color: $blue;
            width: 0;

            @include transition(width 0.3s ease-in-out);
          }

          @include media-queries(2400px) {
            height: 2px;
          }
        }
      }
    }
  }


.footer-bottom {
  width: 100%;
  position: relative;
}


.footer-bottom__element {
  height: 20px;
  display: block;
  margin: 30px auto 10px auto;

  @include media-queries(1280px) {
    height: 30px;
    margin: 60px auto 30px auto;
  }

  @include media-queries(1700px) {
    height: 30px;
    margin: 60px auto 30px auto;
  }

  @include media-queries(2400px) {
    height: 30px;
    margin: 60px auto 30px auto;
  }
  &.rea {
    width: 1000px;
    text-align: center;
    margin-top: 60px;
    font-size: 1.2rem;
    a {
      color: $blue;
    }
    @include media-queries(1280px) {
      font-size: 1.5rem;
    }

    @include media-queries(1700px) {
      font-size: 1.8rem;
    }

    @include media-queries(2400px) {
      font-size: 2.2rem;
    }
  }
}


}
