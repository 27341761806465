@charset "utf-8";

@import "bourbon";
@import "base/html5reset-1.6.1";
@import "base/normalize";
@import "base/variables";
@import "base/mixins";
@import "base/typography";
@import "base/aos";

@import "layout/theme";
@import "components/jquery.bxslider";
@import "components/slider";
@import "components/header";
@import "components/footer";
@import "components/description";
@import "components/download";
