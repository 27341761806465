.description {
  color: $blue;
  font-size: 1.8rem;
  line-height: 1.2;
  padding: 33px 40px;

  &--center {
    text-align: center;
    padding: 50px 10px 20px 10px;
  }

  &--center-2 {
    text-align: center;
    padding: 0px;
  }

  @include media-queries(1280px) {
    font-size: 3.2rem;
    padding: 160px 0 80px 0;

    span {
      display: block;
    }
  }

  @include media-queries(1700px) {
    font-size: 3.8rem;
    // padding: 102px 0;
  }

  @include media-queries(2400px) {
    font-size: 4.5rem;
    // padding: 157px 0;
  }

  &.floorplan {
    @include media-queries(1280px) {
      padding: 160px 0 80px 0;
    }
  }
}

.small-description {
  padding: 46px 0 46px 0;
  font-size: 1.8rem;
  line-height: 1.2;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  @include media-queries(1280px) {
    font-size: 3.2rem;
    padding: 68px 0 68px 0;
  }

  @include media-queries(1700px) {
    font-size: 3.8rem;
    padding: 128px 0 128px 0;
  }

  @include media-queries(2400px) {
    font-size: 4.5rem;
    padding: 180px 0 180px 0;
  }

  &__item {
    width: 50%;
    &:nth-of-type(1) {
      color: $blue;
      text-align: right;
      padding: 0 7px 0 0;

      @include box-sizing(border-box);

      @include media-queries(1280px) {
        padding: 0;
      }

      @include media-queries(2400px) {
        padding: 0;
      }
    }

    &:nth-of-type(2) {
      color: $blue;
      text-align: left;
      padding: 0 0 0 7px;

      @include box-sizing(border-box);

      @include media-queries(1280px) {
        padding: 0 0 0 32px;
      }

      @include media-queries(1700px) {
        padding: 0 0 0 42px;
      }

      @include media-queries(2400px) {
        padding: 0 0 0 60px;
      }
    }
  }
}

.mobile-image {
  display: block;
  padding-top: 0;
  width: 100%;

  @include media-queries(1280px) {
    display: none;
  }

  @include media-queries(1700px) {
    display: none;
  }

  @include media-queries(2400px) {
    display: none;
  }

}

.description-points {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  color: $blue;
  background-color: $gray;
  margin: 40px 0;
  padding: 40px 0;

  @include media-queries(1280px) {
    margin: 40px 0;
    padding: 40px 0;
  }

  @include media-queries(1700px) {
    margin: 40px 0;
    padding: 40px 0;
  }

  @include media-queries(2400px) {
    margin: 40px 0;
    padding: 40px 0;
  }

  &__item {
    position: relative;
    width: 100%;
    font-size: 1.6rem;
    line-height: 1.2;
    text-align: center;
    padding: 25px 75px;


    &:after {
      position: absolute;
      content: "";
      width: 20px;
      height: 1px;
      bottom: 0;
      left: 50%;
      background-color: $blue;

      @include translate(-50%, 0);
    }

    @include media-queries(1280px) {
      width: 27%;
      font-size: 2rem;
      padding: 2%;
      &:after {
        display: none;
      }

      &:nth-of-type(2) {
        border-left: 1px solid $blue;
        border-right: 1px solid $blue;
      }
    }

    @include media-queries(1700px) {
      font-size: 2.2rem;
    }

    @include media-queries(2400px) {
      font-size: 2.4rem;
    }
  }

  &.second {
    @include media-queries(1280px) {
      margin: 100px 0 10px 0;
    }
  }
}

.description-image {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @include media-queries(1280px) {
    margin: 0 0 100px 0;
  }

  @include media-queries(1700px) {
    margin: 0 0 150px 0;
  }

  @include media-queries(2400px) {
    margin: 0 0 175px 0;
  }


  &__image {
    width: 100%;
    height: 100%;
    display: none;

    order: 1;

    @include media-queries(1280px) {
      display: flex;
      width: 60%;
      height: 50%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    color: $blue;
    font-size: 1.6rem;

    order: 1;

    @include box-sizing(border-box);
    span {
      display: block;
      background-color: $white;
      padding: 28px 75px 42px 75px;

      @include media-queries(1280px) {
        padding: 28px 0 42px 0;
      }
    }

    @include media-queries(1280px) {
      width: 40%;
      font-size: 2.2rem;
      padding: 0 32px 0 32px;

      span {
        background-color: inherit;
        padding: 0;
      }
    }

    @include media-queries(1700px) {
      font-size: 2.2rem;
      padding: 0 42px 0 42px;
    }

    @include media-queries(2400px) {
      font-size: 2.2rem;
      padding: 0 60px 0 60px;
    }

    ul {
      list-style: none;

      @include media-queries(1280px) {
        padding: 0 0 0 40px;
      }

      li {
        position: relative;
        line-height: 1.2;
        text-align: center;
        padding: 25px 0;

        &:after {
          position: absolute;
          content: "";
          width: 20px;
          height: 1px;
          bottom: 0;
          left: 50%;
          background-color: $white;

          @include translate(-50%, 0);
        }

        @include media-queries(1280px) {
          text-align: left;
          padding: 0;

          &:after {
            display: none;
          }
        }

        &:before {
          position: absolute;
          content: "";

          @include media-queries(1280px) {
            content: "—";
            left: -40px;
          }
        }
      }
    }
  }

  &__points {
    display: flex;
    flex-direction: column;
    padding: 10px;

    @include media-queries(1280px) {
      font-size: 2rem;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0;
    }

    @include media-queries(1700px) {
      font-size: 2.2rem;
    }

    @include media-queries(2400px) {
      font-size: 2.4rem;
    }

    &__item {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      padding: 20px 0;
      font-size: 1.4rem;
      line-height: 1.2727;
      align-self: center;
      width: 80%;

      @include box-sizing(border-box);

      @include media-queries(1280px) {
        width: 33%;
        // font-size: 2rem;
        padding: 0 8% 0 0;
        align-self: flex-end;
      }

      @include media-queries(1700px) {
        // font-size: 2rem;
        padding: 0 10% 0 0;
      }

      @include media-queries(2400px) {
        // font-size: 2rem;
        padding: 0 130px 0 0;
      }

      strong {
        display: block;
        font-weight: 700;
      }

      &__caption {
        width: 50%;

        @include media-queries(1280px) {
          width: 100%;
        }
      }

      &__img--1 {
        background: url("../img/icon-human.svg") no-repeat;
        background-size: contain;
        background-position: right 30px center;
        width: 50%;
        height: 100px;

        @include media-queries(1280px) {
          width: 100%;
          background-position: left center;
          margin: 0 0 12px 0;
        }

        @include media-queries(1700px) {
          height: 180px;
          margin: 0 0 25px 0;
        }

        @include media-queries(2400px) {
          height: 240px;
        }
      }

      &__img--2 {
        background: url("../img/icon-bike.svg") no-repeat;
        background-size: contain;
        background-position: right 30px center;
        width: 50%;
        height: 90px;

        @include media-queries(1280px) {
          width: 100%;
          background-position: left center;
          margin: 0 0 12px 0;
        }

        @include media-queries(1700px) {
          height: 156px;
          margin: 0 0 25px 0;
        }

        @include media-queries(2400px) {
          height: 216px;
        }
      }

      &__img--3 {
        background: url("../img/icon-transport.svg") no-repeat;
        background-size: contain;
        background-position: right 30px center;
        width: 50%;
        height: 80px;

        @include media-queries(1280px) {
          width: 100%;
          background-position: left center;
          margin: 0 0 12px 0;
        }

        @include media-queries(1700px) {
          height: 130px;
          margin: 0 0 25px 0;
        }

        @include media-queries(2400px) {
          height: 186px;
        }
      }
    }
  }
}
