$font-size: 70%;
$Relative: "Relative-Regular", sans-serif;
$Apercu: "Apercu-Regular", sans-serif;
$Noe: "Noe-Black", sans-serif;
$Myriad: "Myriad-Regular", sans-serif;

@font-face {
    font-family: "Relative-Regular";
    src: url("../fonts/Relative-10Pitch.ttf") format("truetype"),
}

@font-face {
    font-family: "Apercu-Regular";
    src: url("../fonts/apercu_regular_pro.otf") format("truetype"),
}

@font-face {
    font-family: "Noe-Black";
    src: url("../fonts/NoeDisplay-Black.otf") format("truetype"),
}

@font-face {
    font-family: "Myriad-Regular";
    src: url("../fonts/MyriadPro-Regular.otf") format("truetype"),
}

a {
    color: $blue;
    text-decoration: none;

    &.active {
        position: relative;
        &:after {
            position: absolute;
            content: '';
            width: 100%;
            height: 1px;
            left: 0;
            bottom: 5px;
            background-color: $white;
        }
    }
}


h2 {
  font-family: $Noe;
  font-size: 2.5rem;
  letter-spacing: -0.5px;

  @include media-queries(1280px) {
    font-size: 5rem;
    letter-spacing: normal;
  }
}
