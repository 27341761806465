body, html {
    width: 100%;
    min-height: 100% !important;
}

html {
    font-family: $Apercu;
    font-size: $font-size;
    -webkit-font-smoothing: antialiased;
}

body {
    // background-color: $white;
    color: $blue;
    padding: 0;
    font-weight: 400;
    border-left: $blue solid 35px;
    border-right: $white solid 0;
    line-height: 1.3333;

    @include box-sizing(border-box);

    @include media-queries(1280px) {
        border-left: $blue solid 35px;
        border-right: $white solid 35px;
    }

    @include media-queries(1700px) {
        border-left: $blue solid 35px;
        border-right: $white solid 35px;
    }

    @include media-queries(2400px) {
        border-left: $blue solid 35px;
        border-right: $white solid 35px;
    }
}

.container {
    padding-left: 10px;
    padding-right: 10px;
    background-color: none;

    @include media-queries(1280px) {
        padding-left: 50px;
        padding-right: 50px;
    }

    @include media-queries(1700px) {
        padding-left: 50px;
        padding-right: 50px;
    }

    @include media-queries(2400px) {
        padding-left: 50px;
        padding-right: 50px;
    }
}

.full-size {
    padding-left: 0;
    padding-right: 0;

    @include media-queries(1280px) {
        padding-left: 50px;
        padding-right: 50px;
    }

    @include media-queries(1700px) {
        padding-left: 50px;
        padding-right: 50px;
    }

    @include media-queries(2400px) {
        padding-left: 50px;
        padding-right: 50px;
    }
  }

.bg-silver {
    background-color: $white;
}

.border {
    &--top {
        position: relative;
        &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 50%;
            height: 6px;
            background-color: $white;

            @include media-queries(1280px) {
                height: 17px;
            }

            @include media-queries(1700px) {
                height: 23px;
            }

            @include media-queries(2400px) {
                height: 31px;
            }

        }

        &:after {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            width: 50%;
            height: 6px;
            background-color: $white;

            @include media-queries(1280px) {
                height: 17px;
            }

            @include media-queries(1700px) {
                height: 23px;
            }

            @include media-queries(2400px) {
                height: 31px;
            }
        }
    }
}

.overlay {
    display: none;

    .ie9 &,
    .ie10 &,
    .safari & {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $white;
        color: $blue;
        font-family: $Apercu;
        font-size: 3rem;
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            @include translate(-50%, -50%);
        }
    }
}

#bg {
  background-image: url('https://s3.amazonaws.com/reacdn/175pearl/background+pattern.png');
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;

  @include media-queries(1700px) {
    background-size: 80%;
  }

  @include media-queries(2400px) {
    background-size: 70%;
  }
}


#bg-2 {
  background-image: url('https://s3.amazonaws.com/reacdn/175pearl/background+pattern+2.png');
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;

  @include media-queries(1700px) {
    background-size: 80%;
  }

  @include media-queries(2400px) {
    background-size: 70%;
  }
}

.sideHeader {
  position: fixed;
  transform: rotate(270deg);
  transform-origin: left top 0;
  letter-spacing: 2px;
  left: 10px;
  top: 60%;
  color: $white;

  @include media-queries(1280px) {
    left: 10px;
    top: 50%;
  }
}
